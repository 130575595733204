/* use for any tag on your page */
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}
.noSelect:focus {
    outline: none !important;
}

.expanded-area-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    cursor: auto;
    border: solid #cce0ed;

    border-width: 0 0 2px 0;
}

.expanded-area-half {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.expanded-area-half-container {
    margin: 12px;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.expanded-area-top-wrapper {
  display: flex;
  flex-direction: column; /* Stack the top divs naturally */
    height: 100%;
}

.expanded-area-divider {
  /* height: 90%; */
  border: unset;
  border: 0.5px solid lightgrey;
  margin-top: 2%;
}

.expanded-area-table-row {
    /* display: table-row; */
    display: flex;
    vertical-align: inherit;
    border-color: inherit;
    cursor: pointer;
    width: 100%;

    /* justify-content: space-evenly; */
    min-height: 1px;
}

.table-row-item-title {
    display: table-column;
    vertical-align: middle;
    font-family: "Fira Sans";
    /*font-weight: 700;*/
     /*letter-spacing: 1.2px; */
    text-align: left;
    align-self: center;
    padding: 5px;
    float: left;
    min-width: 110px;
    width: 40%;
    justify-self: unset;
    cursor: pointer;
}

.table-center-align {
    display: table-column;
    vertical-align: middle;
    text-align: center;
    align-self: center;
    padding: 5px;
    float: center;
    width: 100%;
    justify-self: unset;
    cursor: pointer;
}

.evaluation-table-left-align {
    display: table-column;
    vertical-align: middle;
    text-align: left;
    align-self: center;
    padding: 5px;
    float: left;
    width: 40%;
    justify-self: unset;
    cursor: pointer;
}

.evaluation-table-right-align {
    display: table-column;
    align-self: center;
    text-align: right;
    margin-left: auto;
}

.expanded-area-half .my-tooltip {
    background-color: #cce0ed;
    border: 1px solid #cce0ed;
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    color: #3d3d3d;
    font-size: 12px;
    border-radius: 3px;
    padding: 8px 12px;
}

.expanded-area-half .my-tooltip .react-tooltip-arrow {
    border-bottom: 1px solid #cce0ed;
    border-right: 1px solid #cce0ed;
}

.expanded-area-table-title {
    text-align: left;
    font-family: "Bebas Neue";
    font-size: 15pt;
    font-weight: 700;
    letter-spacing: 1.2px;
    margin-bottom: 0px;
    min-width: 70px;
}

.expanded-area-table-sub-title {
    text-align: left;
    font-family: "Bebas Neue";
    font-size: 12pt;
    letter-spacing: 1.2px;
    margin-bottom: 0px;
    min-width: 70px;
}

.expanded-area-table-row-title {
    text-align: left;
    font-family: "DM Mono",sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: grey;
    min-width: 70px;
}

.expanded-area-table-column-title {
    text-align: right;
    font-family: "DM Mono";
    font-weight: 700;
    text-transform: uppercase;
    color: grey;
    font-size: 12px;
    min-width: 45px;
}

.expanded-area-table-row-item {
    text-align: right;
    font-family: "DM Mono";
    font-size: 13px;
    cursor: pointer;
    color: #3d3d3d;
    min-width: 45px;
}

.expanded-area-table-row-announcement {
    text-align: center;
    font-family: "DM Mono",sans-serif;
    font-size: 13px;
    cursor: pointer;
    color: grey;
    display: table-cell;
    vertical-align: middle;
}

@media (max-width: 820px) {
  .expanded-area-container {
    display: flex;
    flex-direction: column;
    /*margin: auto 0px;*/
    width: --webkit-fill-available;
    /* justify-content: space-evenly; */
  }

  .expanded-area-half {
    width: auto;
    min-width: unset;
    margin: 0 auto;
    text-align: center;
  }

  .expanded-area-divider {
    width: 100%;
  }
}
