.page-part-white {
    background: white;
    padding-top: 20px;
    padding-bottom: 20px;
}

.page-part-grey {
    background: #f7f7f7;
    padding-top: 20px;
    padding-bottom: 20px;
}

.main-text {
    font-family: "Lato";
    font-size: 14px;
    padding-left: 40px;
    padding-right: 40px;
    line-height: 1.4;
}

.explainer-text {
    font-family: "Fira Sans";
    font-size: 11px;
    color: #585858;
    padding-left: 80px;
    padding-right: 80px;
    line-height: 1.5;
}

.leaderboard-title {
    font-family: "Fira Sans";
    font-size: 20px;
    color: black;
    text-decoration: none;
    text-align: center;
    font-weight: 900;
}

.subtitle-text {
    font-family: "Fira Sans";
    font-size: 13px;
    text-align: center;
    font-style: italic;
}

.evaluation-tabs {
    display: flex;
    justify-content: center;
    width: 100%;
    background: #f7f7f7;
    box-sizing: border-box;
}

.evaluation-tab {
    height: 35px;
    margin: 0 2px;
    border-width: 4px;
    border-style: solid;
    border-radius: 10px 10px 0px 0px;
    box-sizing: border-box;

    font-family: Fira Sans, sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 900;
}

.evaluation-tab.active {
    background: white;
    border-color: white;
    color: black;

    text-decoration-line: underline;
    text-decoration-color: #cce0ed;
    text-decoration-thickness: 3px;
}

.evaluation-tab.inactive {
    background: #f0f0f0;
    border-color: #f0f0f0;
    color: grey;
}