.content-container {
    padding-left: 25%;
    padding-right: 25%;
}

@media(max-width: 1400px) {
    .content-container {
        padding-left: 21%;
        padding-right: 21%;
    }
}

@media(max-width: 1350px) {
    .content-container {
        padding-left: 18%;
        padding-right: 18%;
    }
}

@media(max-width: 1200px) {
    .content-container {
        padding-left: 15%;
        padding-right: 15%;
    }
}

@media(max-width: 1100px) {
    .content-container {
        padding-left: 13%;
        padding-right: 13%;
    }
}

@media(max-width: 1000px) {
    .content-container {
        padding-left: 10%;
        padding-right: 10%;
    }
}

@media(max-width: 550px) {
    .content-container {
        padding-left: 7%;
        padding-right: 7%;
    }
}

@media(max-width: 500px) {
    .table-row-item-team {
        width: 40%;
    }
    .table-header-item-team {
        width: 40%;
    }

     .table-row-item {
        font-size: 12px;
    }
    .table-header-item {
        font-size: 11px;
    }
    .table-row-item-team {
        font-size: 14px;
    }
    .table-header-item-team {
        font-size: 11px;
    }
    .table-row-item.bolder {
        font-size: 13px;
    }
    .table-header-item.bolder {
        font-size: 12px;
    }

    .searchBox {
        font-size: 13px;
    }

    .table-row-team-logo {
        padding-top: 1px;
    }
}

@media(max-width: 425px) {
    .content-container {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media(max-width: 360px) {
    .content-container {
        padding-left: 3%;
        padding-right: 3%;
    }
    .table-row-item-team {
        width: 50%;
    }
    .table-header-item-team {
        width: 50%;
    }
}