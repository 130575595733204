
.explainer-link:link {
    color: black;
  }

  .explainer-link.inactive {
    color: #888888;
    text-decoration: underline;
  }

  .explainer-link.inactive:hover {
    color: #888888;
  }

  /* visited link */
  .explainer-link:visited {
    color: black;
  }

  /* mouse over link */
  .explainer-link:hover {
    color: #FE7AAE;
  }

  .table-options {
    display: grid;
    grid-template-areas: "search-field display-field";
  }

  .search-field {
    display: grid;
    grid-area: search-field;

    margin-right: auto;
    margin-left: 10px;
  }

  .display-select {
    display: grid;
    grid-area: display-field;

    width: 125px;
    margin-left: auto;
    margin-right: 10px;
  }