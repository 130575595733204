.searchBox {
    height: 38px;
    border-radius: 15px;
    border: 0px;
    box-shadow: none;
    font-family: 'Fira Sans';
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;

    color: black;
}

.searchBox:focus {
    outline-color: #3F72AF;
}

::placeholder {
    color: #8e8e8e
}