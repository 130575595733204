.table-header {
    /* display: table-header-group; */
    display: block;

}

.table-header-row {
    /* display: table-row; */
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    vertical-align: inherit;
    border-color: inherit;
}

.table-header-item {
    display: table-column;
    font-family: "DM Mono";
    text-transform: uppercase;
    color: grey;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
}

.table-header-item.right-align {
    text-align: right;
    margin-left: auto;
    padding-right: 10px;
}

.table-header-item.left-align {
    text-align: left;
    margin-right: auto;
    padding-left: 10px;
}

.table-header-item.sorted {
    background: #112D4E;
    color: white;
}

.table-header-item-team {
    display: table-cell;
    text-align: left;
    min-width: 110px;
    width: 27%;
    align-self: end;
    font-family: "DM Mono";
    text-transform: uppercase;
    color: grey;
    padding: 8px 6px;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
}
