.daily-container {
    border-radius: 15px;
    border-color: #f0f0f0;
    border-style: solid;
    border-width: 4px;
    display: block;
    background: white;
    padding: 8px;

    width: 780px;
}

@media (max-width: 820px) {
    .daily-container {
       width: 390px;
    }
}

@media (max-width: 460px) {
    .daily-container {
        width: calc(100% - 40px);
        margin: 0 20px;
        box-sizing: border-box;
    }
}