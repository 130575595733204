.table-body {
    /* display: table-row-group;
    vertical-align: middle;
    border-color: inherit; */
    display: flex;
    flex-direction: column;
}

.table-row-container {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.table-row {
    display: flex;
    vertical-align: inherit;
    border-color: inherit;
    cursor: pointer;
    min-height: 32px;
}

.table-row:nth-child(odd) {
    background-color: #cce0ed;
}

.table-row.odd {
    background-color: white;
}

.font-title {
    font-family: "Fira Sans";
    font-weight: 500;
}

.font-number {
    font-family: "DM Mono";
    font-weight: 500;
    font-size: 13px;
    color: #3d3d3d;
}

.table-row-item {
    display: table-column;
    vertical-align: middle;
    align-self: center;
    cursor: pointer;
    padding-top: 5px;
    padding-bottom: 5px;
}

.table-row-item.right-align {
    text-align: right;
    margin-left: auto;
    padding-right: 10px;
}

.table-row-item.left-align {
    text-align: left;
    margin-right: auto;
    padding-left: 10px;
}

.table-row-item.sorted {
    font-weight: 800;
}

.table-row-item span:hover {
    color: hotpink;
    cursor: pointer;
}

.inactive > .table-row-expanded {
    display: none;
}

.active > .table-row-expanded {
    display: block;
}

.active > .table-row {
    background: #112D4E;
    -webkit-transition: background-color 250ms linear;
    -ms-transition: background-color 250ms linear;
    transition: background-color 250ms linear;
}

.active > .table-row > .table-row-item {
    color: white;
}

.active > .table-row > .table-row-item-team {
    color: white;
}

.inactive > .table-row.even {
    background: #cce0ed;
    color: unset;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}

.inactive > .table-row.odd {
    background: white;
    color: unset;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}