/* use for any tag on your page */
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
}
.noSelect:focus {
    outline: none !important;
}

.leaderboard-container {
    background: #fdfdfd;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    /*justify-content: space-evenly;*/
    cursor: auto;
    width: 560px;

    padding: 0;

    border-color: #f0f0f0;
    border-width: 4px;
    border-style: solid;
    border-radius: 15px;

}

.leaderboard-tabs {
    display: flex;
    width: 100%;
    background: #f0f0f0;
    box-sizing: border-box;
}

.leaderboard-tab {
    height: 35px;
    border-width: 4px;
    border-radius: 10px 10px 0px 0px;
    border-style: solid;
    box-sizing: border-box;

    font-family: Fira Sans, sans-serif;
    text-transform: uppercase;
    font-weight: 900;
}

.leaderboard-tab.active {
    background: #fdfdfd;
    border-color: #fdfdfd;
    color: black;

    text-decoration-line: underline;
    text-decoration-color: #cce0ed;
    text-decoration-thickness: 3px;
}

.leaderboard-tab.inactive {
    background: #f0f0f0;
    border-color: #f0f0f0;
    color: grey;
}

.leaderboard-sub-container {
    border-radius: 15px;
    /*display: block;*/
    background: #fdfdfd;
    padding: 4px;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    cursor: auto;
}

.leaderboard-half {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.leaderboard-half-container {
    margin: 12px;
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.leaderboard-divider {
  /* height: 90%; */
  border: unset;
  border: 0.5px solid lightgrey;
  margin-top: 2%;
}

.leaderboard-table-row {
    display: flex;
    cursor: pointer;
    width: 100%;
    min-height: 28px;
    margin: 1px;
}

.leaderboard-table-left-align {
    display: flex;
    align-self: center;
    margin-left: auto;
    cursor: pointer;
}

.leaderboard-table-right-align {
    display: table-column;
    align-self: center;
    text-align: right;
    margin-left: auto;
}

.leaderboard-half .my-tooltip {
    background-color: #cce0ed;
    border: 1px solid #cce0ed;
    font-family: "Fira Sans", sans-serif;
    font-weight: 400;
    color: #3d3d3d;
    font-size: 12px;
    border-radius: 3px;
    padding: 8px 12px;
}

.leaderboard-half .my-tooltip .react-tooltip-arrow {
    border-bottom: 1px solid #cce0ed;
    border-right: 1px solid #cce0ed;
}

.leaderboard-table-title {
    text-align: left;
    font-family: "Bebas Neue";
    font-size: 15pt;
    font-weight: 700;
    letter-spacing: 1.2px;
    margin-bottom: 0px;
    min-width: 70px;
}

.leaderboard-table-sub-title {
    text-align: left;
    font-family: "Bebas Neue";
    font-size: 12pt;
    letter-spacing: 1.2px;
    margin-bottom: 0px;
    min-width: 70px;
}



.leaderboard-table-column-name {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "DM Mono";
    font-weight: 700;
    text-transform: uppercase;
    color: grey;
    font-size: 12px;
    min-width: 45px;
}

.leaderboard-table-row-square {
}

.leaderboard-table-row-item-left {
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: "DM Mono", sans-serif;
    font-size: 13px;
    cursor: pointer;
    color: #585858;
    margin: 1px;
    font-weight: 700;
    height: 100%;
    min-width: 90px;
}

.leaderboard-table-row-item {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "DM Mono", sans-serif;
    font-size: 13px;
    cursor: pointer;
    color: #3d3d3d;
    margin: 1px;
    font-weight: 600;
    height: 100%;
    min-width: 45px;
}
.leaderboard-table-row-item-light {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "DM Mono", sans-serif;
    font-size: 11px;
    cursor: pointer;
    color: #3d3d3d;
    margin: 1px;
    font-weight: 500;
    height: 100%;
    min-width: 30px;
}

.leaderboard-table-row-announcement {
    text-align: center;
    font-family: "DM Mono",sans-serif;
    font-size: 13px;
    cursor: pointer;
    color: grey;
    display: table-cell;
    vertical-align: middle;
}

@media (max-width: 650px) {
    .leaderboard-container {
        display: flex;
        margin: auto 0px;
        width: 280px;
        justify-content: space-evenly;
    }

    .leaderboard-sub-container {
        display: flex;
        flex-direction: column;
    }

    .leaderboard-half {
        width: auto;
        min-width: unset;
        text-align: center;
    }

    .leaderboard-divider {
    width: 100%;
    }
}
