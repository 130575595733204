body {
  margin: 0;
  font-family: -apple-system,  BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
    text-align: center;
    font-family: "Bebas Neue";
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1.2px;
    margin-bottom: 0px;
    margin-left: 40px;
	margin-right: 40px;
}
