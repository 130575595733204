.update-text {
    line-height: 25px;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 16px;
}

.update-container {

}

