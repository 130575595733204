.pagination-container {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: fit-content
}

.left-arrow {
    display: flex;
    flex-direction: row;
    padding: 8px;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
}

.right-arrow {
    display: flex;
    flex-direction: row;
    padding: 8px;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
}

.text {
    padding: 5px;
    font-family: 'Space Grotesk', sans-serif;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1.2px;
}


.current-page {
    padding: 15px;
    font-family: 'Space Grotesk';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 1.2px;
}