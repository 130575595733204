/* use for any tag on your page */
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

.expanded-area-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  cursor: auto;
}

.left {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.right {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 250px;
}

.divider {
  /* height: 90%; */
  border: unset;
  border: 0.5px solid lightgrey;
  margin-top: 2%;
}

.expanded-area-table-row {
    /* display: table-row; */
    display: flex;
    vertical-align: inherit;
    border-color: inherit;
    cursor: pointer;
    width: 100%;

    /* justify-content: space-evenly; */
    min-height: 32px;
}

.table-row-item-title {
    display: table-column;
    vertical-align: middle;
    font-family: "Fira Sans";
    /*font-weight: 700;*/
     /*letter-spacing: 1.2px; */
    text-align: left;
    align-self: center;
    padding: 5px;
    float: left;
    min-width: 110px;
    width: 40%;
    justify-self: unset;
    cursor: pointer;
}

.expanded-area-table-title {
    text-align: center;
    font-family: "Bebas Neue";
    font-size: 25px;
    font-weight: 700;
    letter-spacing: 1.2px;
    margin-bottom: 0px;

    text-align: left;
    font-size: 14pt;
    min-width: 70px;
}

.expanded-area-table-column-title {
    text-align: right;
    font-family: "DM Mono";
    font-weight: 700;
    text-transform: uppercase;
    color: grey;
    font-size: 12px;
    min-width: 60px;
}

.expanded-area-table-row-item {
    text-align: right;
    font-family: "DM Mono";
    font-size: 13px;
    cursor: pointer;
    color: #3d3d3d;
    min-width: 60px;
}

.expanded-area-table-row-title {
    text-align: left;
    font-family: "DM Mono",sans-serif;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 700;
    color: grey;
    min-width: 70px;
}

/*@media (max-width: 600px) {*/
/*  .expanded-area-container {*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    margin: auto 8px;*/
/*    width: --webkit-fill-available;*/
/*    !* justify-content: space-evenly; *!*/
/*  }*/

/*  .left {*/
/*    width: auto;*/
/*    min-width: unset;*/
/*    margin: 0 auto;*/
/*    text-align: center;*/
/*  }*/

/*  .right {*/
/*    !*width: auto;*!*/
/*    min-width: unset;*/
/*    margin: 0 auto;*/
/*    text-align: center;*/
/*  }*/

/*  .divider {*/
/*    width: 100%;*/
/*  }*/
/*}*/
