.Header {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  z-index: 1000;

  height: 25px;

  background-color: white;
  border-color: #f7f7f7;
  border-width: 4px;
  border-style: solid;
}

.Logo {
  display: grid;
  grid-area: logo;
  justify-items: left;
  align-self: center;
}

.dropdown {
  position: absolute;
  right: 30px;

  color: black;
  text-decoration: none;
  text-align: center;
  font-family: "Fira Sans", sans-serif;
  font-size: 18px;
  font-weight: 900;
}

.dropbtn {
  border-radius: 10px;
  border-width: 0;
  border-style: solid;
  border-color: white;
  color: grey;
  padding: 10px;
  font-size: 16px;
}
.dropdown:hover .dropbtn {background-color: #ddd;}

.dropdown-content {
  position: absolute;
  right: 0;
  font-size: 14px;
  font-weight: 700;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 10px;
  text-decoration: none;
  display: block;
}
.dropdown-content a:hover {background-color: #ddd;}

@media (max-width: 700px) {
  .dropdown {
    right: 10px;
  }
}

